import { HEADER } from "../../constant";
import axios from "../../utils/axios";

const CreateUserSessionApi = async () => {

  const res = await axios.post('api/stripe/createUserSession', {}, HEADER());
  
  return res.data
};

export { CreateUserSessionApi };
