function SenderPaymentCancel() {

    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div className="max-w-[90%] text-[20px]">
                <p className="text-warning">You canceled payment. </p>
                <p>To ensure your email is delivered to the recipient's primary inbox, please complete payment. </p>
            </div>
        </div>
    );
}

export default SenderPaymentCancel;
