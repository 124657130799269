import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CreateSenderSessionApi } from "../api/stripe/createSenderSession";
import { loadStripe } from "@stripe/stripe-js";
import { StripePubKey } from "../constant";
import { ApiError } from "../types";
import toast from "react-hot-toast";

const stripePromise = loadStripe(StripePubKey);

function SenderPayments() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null); // Specify error type

    useEffect(() => {
        const queryParam = new URLSearchParams(location.search);
        const transactionId = queryParam.get('transaction_id');

        const fetchData = async () => {
            if (transactionId) {
                try {
                    setLoading(true);
                    const stripe = await stripePromise;
                    const res = await CreateSenderSessionApi(transactionId);
                    const sessionId = res.sessionId

                    if (!stripe) {
                        toast.error("Failed to load stripe");
                        return;
                    }

                    if (!sessionId) {
                        toast.error("sessionId not found");
                        return;
                    }
                    const { error } = await stripe?.redirectToCheckout({ sessionId });
                    if (error) {
                        toast.error("Error redirecting to Stripe");
                    }
                } catch (err) {
                    const errorMessage = (err as ApiError).response?.data.message || 'An error occurred';
                    setError(errorMessage);
                }
            } else {
                setLoading(false);
            }
        };

        fetchData();
    }, [location]);

    return (
        loading ?
            <div className="w-full h-screen flex justify-center items-center">
                <CircularProgress size="100px" />
            </div>
            : <div className="w-full h-screen flex justify-center items-center">
                <p>Error: {error}</p>
            </div>
    );
}

export default SenderPayments;
