import { useRoutes } from "react-router-dom";
import Layout from "./layout/index";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword"
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Homepage from "./pages/Homepage";
import Payments from "./pages/Payments";
import SenderPayments from "./pages/SenderPayment";
import StripeOauthCallback from "./components/main_component/stripe/StripeOAuthCallback";
import AdminDashboard from "./pages/admin/Dashboard";
import AdminTransactions from "./pages/admin/Transactions";
import CancelSubscription from "./pages/CancelSubscription";
import Subscribe from "./pages/Subscribe";
import SuccessSubscription from "./pages/SuccessSubscription";
import SenderPaymentCancel from "./pages/SenderPaymentCancel";
import SenderPaymentSuccess from "./pages/SenderPaymentSuccess";

export default function Router() {
  const router = [
    {
      element: <Layout />,
      children: [
        {
          path: "/home",
          element: <Homepage />,
        },
      ],
    },
    {
      path: '/',
      element: <Homepage />
    },
    {
      path: 'auth/login',
      element: <Login />
    },
    {
      path: 'auth/signup',
      element: <SignUp />
    },
    {
      path: 'auth/forgotpassword',
      element: <ForgotPassword />
    },
    {
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      path: 'privacypolicy',
      element: <PrivacyPolicy />
    },
    {
      path: 'sender-payments',
      element: <SenderPayments />
    },
    {
      path: 'payment/sender-pay/cancel',
      element: <SenderPaymentCancel />
    },
    {
      path: 'payment/sender-pay/success',
      element: <SenderPaymentSuccess />
    },
    {
      path: 'stripe/callback',
      element: <StripeOauthCallback />
    },
    {
      path: 'stripe/cancel',
      element: <CancelSubscription />
    },

    {
      path: 'stripe/success',
      element: <SuccessSubscription />
    },

    {
      path: 'subscribe',
      element: <Subscribe />
    },
    {
      path: 'admin',
      element: <AdminDashboard />
    },

    {
      path: 'admin/transactions',
      element: <AdminTransactions />
    }
  ];
  return useRoutes(router);
}
