import Button from "@mui/material/Button";
import PixIcon from "@mui/icons-material/Pix";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import { StripePubKey } from "../../../constant";
import { CreateUserSessionApi } from "../../../api/stripe/createUserSession";

const stripePromise = loadStripe(StripePubKey);

export default function UserMembershipComponent() {
  const subscribe = async () => {
    try {
      const stripe = await stripePromise;

      const res = await CreateUserSessionApi();

      const { sessionId } = res;

      if (!stripe) {
        toast.error("Failed to load Stripe.");
        return;
      }
      
      if (!sessionId) {
        toast.error("sessionId not found");
        return;
      }
      
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        toast.error("Error redirecting to Stripe.");
      }
    } catch (error) {
      console.log(error)
      toast.error("Error connecting to Stripe.");
    }
  };

  return (
    <>
      <div className="w-[100%] h-auto relative ">
        <div className="w-[100%] h-auto bg-white pb-8 rounded-[5px]">
          <div className="w-[100%] pt-8 pl-40 text-[18px] font-bold text-[#121212]">
            <a>Stripe</a>
          </div>

          <div className="w-[100%] pt-8 pl-40 text-[18px] text-[#121212]">
            <a>$5 per month</a>
          </div>

          <Button
            onClick={subscribe}
            variant="contained"
            style={{
              width: "70%",
              marginLeft: "15%",
              height: "50px",
              marginTop: "20px",
              borderColor: "#666",
              alignItems: "center",
              display: "flex",
            }}
            startIcon={<PixIcon style={{ fontSize: "40px" }} />}
          >
            Subscribe
          </Button>
        </div>
      </div>
    </>
  );
}
