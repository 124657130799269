function SenderPaymentSuccess() {

    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div className="max-w-[90%] text-[20px]">
                <p className="text-primary">Thank you for your payment! </p>
                <p>Your email will be moved to reciever's primary inbox from the promotions or spam folder.</p>
            </div>
        </div>
    );
}

export default SenderPaymentSuccess;
