import axios from "../../utils/axios";

const CreateSenderSessionApi = async (transactionId : string) => {

  const res = await axios.post('api/stripe/createSenderStripeSession', {transactionId});
  
  return res.data
};

export { CreateSenderSessionApi };
